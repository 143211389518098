import Recaptcha from "react-google-recaptcha";
import "./index.css"
import React, { useRef, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import base64 from "base-64";
import utf8 from "utf8";

export default function List() {
    let history = useHistory();
    const captchaRef = useRef(null);
    let {encodedId, emailAddress} = useParams();
    const [formValues, setFormValues] = useState({
        name: "",
        email: emailAddress || "",
        newEpisodes: true,
        bsfiBooks: true,
        otherBooks: true,
    });
    useEffect(() => {
        if (encodedId) {
            console.log("GETTING SUB INFO")
            let bytes = base64.decode(encodedId);
            let text = utf8.decode(bytes);
            let id = text.split(":")[1] / 97;
            axios.get("/api/list/" + id)
            .then(({data}) => {
                console.log("SUBSCRIBER", data)
                setFormValues({...data, bsfiBooks: data.books})
            })
        }
    }, [encodedId])
    const handleSubmit = (event) => {
        localStorage.setItem("emailAddress", formValues.email);emailAddress = formValues.email;
        event.preventDefault();
        const token = captchaRef.current.getValue();
        captchaRef.current.reset();
        console.log("TOKEN", token);
        axios.post("/api/list", { token, ...formValues })
            .then(response => {
                console.log(response.data);
                if (!response.data.hasBookmark) {
                    history.push("/bookmark/" + response.data.newSubscriberId);
                } else {
                    history.push("/");
                }
            })
            .catch(error => console.error(error));
    }

    const handleChange = ({target}) => {
        setFormValues({...formValues, [target.name]: target.type==="text" ? target.value : target.checked})
        
    }

    const unsubscribe = () => {
        setFormValues({...formValues, newEpisodes: false, bsfiBooks: false, otherBooks:false})
    }

    const captchaChange = (value) => {
        console.log("CAPTCHA", value);
    }

    return (
        <>
            <h1 className="fiction">Insomniacs' Snoozeletter</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        value={formValues.name}
                        onChange={handleChange} />
                </div>
                <div>
                    <label htmlFor="email">Email</label>
                    <input
                        type="text"
                        name="email"
                        id="email"
                        value={formValues.email || emailAddress || ""}
                        onChange={handleChange} />
                </div>
                <div>
                    <label className="forCheckbox" htmlFor="newEpisodes"><input 
                        type="checkbox" 
                        name="newEpisodes"
                        id="newEpisodes"
                        checked={formValues.newEpisodes}
                        onChange={handleChange} /> Alert me to new episodes</label>
                    
                </div>
                <div>
                    <label className="forCheckbox" htmlFor="bsfiBooks"><input 
                        type="checkbox" 
                        name="bsfiBooks"
                        id="bsfiBooks"
                        checked={formValues.bsfiBooks}
                        onChange={handleChange} /> Keep me posted about Bedtime Stories books</label>
                    
                </div>
                <div>
                    <label className="forCheckbox" htmlFor="otherBooks"><input 
                        type="checkbox" 
                        name="otherBooks"
                        id="otherBooks"
                        checked={formValues.otherBooks}
                        onChange={handleChange} /> I'm curious about other books by Rich Hosek</label>
                    
                </div>
                <div style={{display: encodedId ? "block": "none"}}>
                    <button onClick={unsubscribe}>Unsubscribe All</button>
                </div>
                <Recaptcha
                    sitekey="6LdW4ucjAAAAAAYRupzUoTtjfzkBR_r51UioTynE"
                    ref={captchaRef}
                    onChange={captchaChange}
                    className="recaptcha" />
                <button>Submit for FREE Bookmark</button>
            </form>
        </>


    )
}